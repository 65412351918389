import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia"; 
import Typography from "@material-ui/core/Typography";
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from "@material-ui/core/Grid"; 

const useStyles = makeStyles({
  card: { 
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px"
  },
  media: {
    height: "auto",
    width: "100%",
  },
  sectionTitle: {
    fontFamily:"CommonGround_Bold",
    fontSize:36,
    fontWeight:"noramal",
    textTransform: 'uppercase',
    paddingTop: "5px",
   // marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 450,
    textAlign:"left",
  },
  cardtitle: {
    fontFamily:"CommonGround_Bold",
    fontSize:20,
    fontWeight:"bold",
    textTransform: 'uppercase',
    // paddingBottom: '5px',
  },
  cardSubTitle: {
    fontFamily:"CommonGround_Normal",
    fontSize:14,
    fontWeight:"normal",
    // paddingBottom: '5px',
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    width:"100%",
  }
});

/**
 * Four Featured Exhibition will list as a Material Card Style
 */
export default function ExhibitionFeatured(props) {
  const classes = useStyles();  
  const [exbData] = useState(props.node?props.node:[]);
  function clickOverview(dataNode){
     props.onChildClick(dataNode);   
  }
  return (    
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography 
            gutterBottom
            variant="h4"
            component="h1"
            className={classes.sectionTitle}
          >
            EXHIBITIONS
          </Typography>
        </Grid> 
        {exbData.map((node,indx) => (
            <Grid key={node.id} item xs={12}>
            <Card  className={classes.card}>
            <ButtonBase  className={classes.cardAction} onClick={event => {clickOverview({...node})}}>
              <CardMedia
                component="img"               
                className={classes.media}
                image={node.field_managed_image_url} 
              />
              <CardContent>
                <Typography
                  className={classes.cardtitle}
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color="textPrimary"
                >
                  {node.title}
                </Typography>
                <Typography 
            gutterBottom
            variant="h5"
            component="h3"
            color="textSecondary"
            className={classes.cardSubTitle}
          >
            {node.field_date_time_description}
          </Typography>
              </CardContent>  
              </ButtonBase>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>     
  );
}
