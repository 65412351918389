import React from 'react';
/**
 * store the state variable in local storage 
 * @param {*} key 
 * @param {*} defaultValue 
 * @returns 
 */
export default function usePersistedState(key, defaultValue) { 
  const [state, setState] = React.useState(() => {
    const persistedState = localStorage.getItem(key);
    return persistedState ? JSON.parse(persistedState) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);
  return [state, setState];
}