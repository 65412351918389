import React from 'react';
import ReactDOM from 'react-dom'; 
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import Theme from './theme'; // Import Theme file
import { BrowserRouter as Router } from 'react-router-dom';
/**
 * Starting Point of the app. 
 * Here we can add global theme
 */
ReactDOM.render(  
  <Router ><ThemeProvider theme={Theme}><App /></ThemeProvider></Router> ,  
  document.querySelector('#root'),
);
