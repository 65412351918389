import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useLocation, useHistory } from "react-router-dom"; 
import RoomIcon from '@material-ui/icons/Room';
import usePersistedState from "../usePersistedState";

const useStyles = makeStyles({
  root: { 
    width: "100%",
    paddingBottom: 50, 
  },
  sectionBody: {
    fontFamily: "CommonGround_Normal"
  },
  card: {
    // maxWidth: 450,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px",
    boxShadow: "none",
  },
  cardContent: {
    paddingLeft: "inherit",
  },
  media: {
    height: "auto",
    width: "100%",
  },
  sectionTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: 36,
    fontWeight: "normal",
    paddingTop: "5px", 
    marginRight: "auto",
    maxWidth: 450,
    textAlign: "left",
    textTransform: 'uppercase',
  }, 
  cardSubTitle1: {
    fontFamily: "CommonGround_Bold",
    fontSize: 30,
    fontWeight: "normal", 
  }, 
  locationTitle: {
    fontFamily: "NimbusSanL-Bold",
    fontSize: 14,
    fontWeight: "normal",
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap', 
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    width: "100%",
  },
  transparentBtn: {
    width:'100%',
    color: '#111111',
    border: '1px solid rgb(222 222 222 / 100%)',
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: 'capitalize',
}
});
/**
 * Page will display Detailed information of an Exhibition with Image, video etc.
 */
export default function ExhibitionInfo(props) {
  const classes = useStyles(); 
  const location = useLocation();  
  let history = useHistory(); 
  const [exibInfo, setExibInfo] = usePersistedState('exibInfo',{});
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);  
  React.useEffect(() => {
    let isSubscribed = true; 
    const propertyFetch = async () => {
      if (location.props && isSubscribed) { 
        if(location.props.exbData)
         {
           console.log('location.props.exbData',location.props.exbData)
            setExibInfo(location.props.exbData);  
         } 
       }
      return () => isSubscribed = false
    }
    propertyFetch();
  }, [location]);
  return (
    <div  className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            variant="h4"
            component="h1"
            className={classes.sectionTitle}
          >
            {exibInfo.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardMedia
              component="img"
              className={classes.media}
              image={exibInfo.field_managed_image_url}
            />
            <CardContent className={classes.cardContent}>         
              <Typography
                gutterBottom
                variant="h5"
                component="h3"
                className={classes.cardSubTitle1}
              >
                {exibInfo.field_date_time_description}
              </Typography>
              {exibInfo.field_agoc_multi_locations && exibInfo.field_agoc_multi_locations!=""?
              <Typography
                gutterBottom
                variant="h5"
                component="h3" 
                className={classes.locationTitle}
              ><RoomIcon />
                {exibInfo.field_agoc_multi_locations && exibInfo.field_agoc_multi_locations}
              </Typography>:null}             
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container direction="row" justifycontent="center" alignItems="center">
            <Grid item xs={12}>
              <Button variant="outlined" href={"https://visit.ago.ca"} size="large" className={classes.transparentBtn}>
              BUY TICKETS
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              
              { exibInfo.title==='Andy Warhol' ? 
              <Button variant="outlined"  onClick={()=>{ history.push('/andy-warhol') }} size="large" className={classes.transparentBtn}>
               AUDIENCE RESOURCES
              </Button>:
              exibInfo.title==='Picasso: Painting the Blue Period'?
              <Button variant="outlined"  onClick={()=>{ history.push('/picasso') }} size="large" className={classes.transparentBtn}>
              AUDIENCE RESOURCES
             </Button>:null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <style>{"\
        .video-embed-field-responsive-video{position:relative;}.video-embed-field-responsive-video:after{content:'';display:block;padding-bottom:56.25%;}.video-embed-field-responsive-video iframe{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;margin:0;}.video-embed-field-responsive-modal{max-width:100%;}\
        img, video {\
          max-width: 100%;\
          height: auto;\
        }\
        img {\
        border: 0;\
        }\
      "}</style>
      <div className={classes.sectionBody} dangerouslySetInnerHTML={{ __html: exibInfo.field_referenced_content }}></div>
    </div>
  );
}
