 
import PropTypes from "prop-types"
import React from "react"
/**
 * Website Main Header definition
 */
const Header = ({ siteTitle }) => (
  <>
    <header
      style={{
        background: `red`,
        width: '100%',
        position: 'fixed',
        top: 0,
        display: 'none',
      }}
    >
      <div
        style={{
          margin: `0 auto`,
        }}
      >
      </div>
    </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
