import React from "react";
import ExhibitionFeatured from "./exhibition_featured";
import ExhibitionSlides from "./feature_slide";
import { makeStyles } from "@material-ui/core/styles";
import EventsToday from "./events"; 
import { useHistory } from "react-router-dom";
import usePersistedState from "../usePersistedState"; 

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingBottom: 50,
  },
  navbar: {
    width: "100%",
    top: "auto",
    bottom: 0,
    padding: "0",
    background: "#e02626",
    position: "fixed"
  },
  icon: {
    color: "White"
  },
  tabs: {
    background: "#111111"
  },
  tab: {
    color: "White",
    fontFamily: "CommonGround_Bold"
  },
  fab: {
    top: 4,
    fontStyle: "bold",
    left: 21,
    position: "fixed",
    color: "#F0F0F0",
    backgroundColor: "transparent",
    boxShadow: "none"
  },
}));

/**
 * This is the main entry page of the App, All other components/page are defined as it's child components
 */
export default function App(props) {
  const classes = useStyles();
  let history = useHistory();  
  const [homeData,setHomeData] = usePersistedState('homeData', []);
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []); 
  React.useEffect(() => {
    if(props.homeData)
          setHomeData(props.homeData);
  }, [props]); 
  function handleViewClick(selectData) {
    history.push({ pathname: '/exhibition-info', props: { exbData: selectData } });
  }
  
  return (
    <> 
      <div className={classes.root}> 
        <ExhibitionFeatured  /* List the Featured exhibitions */
          node={homeData && homeData.Fetured}
          rerender={true}
          onChildClick={nodeData => {
            handleViewClick(nodeData);
          }}
        />
        <ExhibitionSlides
          node={homeData && homeData.Normal}
          onChildClick={nodeData => {
            handleViewClick(nodeData);
          }}
        />
       {/*  <AudienceResourcesCard
          node={homeData && homeData.Fetured}
          rerender={true}
          onChildClick={nodeData => {
            handleViewClick(nodeData);
          }} /> */}
        {/* <PicassoAudienceResourcesCard
          node={homeData && homeData.Fetured}
          rerender={true}
          onChildClick={nodeData => {
            handleViewClick(nodeData);
          }} /> */}
        <EventsToday node={homeData && homeData.Events} /> {/* List the today's events */}
        {/*  <Tours node={data.Tours} /> */} {/* List the tours i */}
      </div>
    </>
  );
}
