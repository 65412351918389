import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Map from "@material-ui/icons/Map";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import Fab from "@material-ui/core/Fab";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: theme.palette.background.paper,
        width: "100%",
        paddingBottom: 50,
    },
    navbar: {
        width: "100%",
        top: "auto",
        bottom: 0,
        padding: "0",
        background: "#e02626",
        position: "fixed"
    },
    icon: {
        color: "White"
    },
    tabs: {
        background: "#111111"
    },
    tab: {
        color: "White",
        fontFamily: "CommonGround_Bold"
    },
    fab: {
        top: 4,
        fontStyle: "bold",
        left: 21,
        position: "fixed",
        color: "#F0F0F0",
        backgroundColor: "transparent",
        boxShadow: "none"
    },
}));

/**
 * This is the main entry page of the App, All other components/page are defined as it's child components
 * Application bottom navigation bar
 */
export default function AppBottomBar(props) {    
    const classes = useStyles();
    let history = useHistory();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0)
            history.push({ pathname: '/' });
        if (newValue === 1)
            history.push({ pathname: '/map' });
        if (newValue === 2)
            history.push({ pathname: '/info' });
    };
    const handleBackClick = () => { 
       history.goBack();
    };  
    return (
        <>
            <AppBar className={classes.navbar} position="sticky">   {/* Definition of Bottom Navigation */}
               {props.path!=='/' && props.path!=='/map' && props.path!=='/info' && <Fab
                    onClick={handleBackClick}
                    aria-label="add"
                    className={classes.fab}
                >
                    <ChevronLeftIcon />
                </Fab>}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.tabs}
                    variant="fullWidth"
                    aria-label="bottomnav"
                >
                    <Tab
                        label="What's On"
                        className={classes.tab}
                        icon={<RemoveRedEye className={classes.icon} />}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Map"
                        className={classes.tab}
                        icon={<Map className={classes.icon} />}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Info"
                        className={classes.tab}
                        icon={<ErrorOutline className={classes.icon} />}
                        {...a11yProps(2)}
                    />
                </Tabs>
            </AppBar>
        </>
    );
}
