import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";


/**
 * Definition of page styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px"
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  sectionTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: 36,
    fontWeight: "normal",
    paddingTop: "5px",
    marginRight: "auto",
    textTransform: 'uppercase',
    maxWidth: 450,

  },
  card: {
    display: "flex",
    marginRight: "auto",
    marginBottom: "30px"

  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  content: {
    flex: "1 0 auto",
    maxWidth: 500
  },
  cover: {
    width: 157
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  sectionbody: {
    fontFamily: "Arial"
  },
  cardSubtitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: 'uppercase',
    // paddingBottom: '5px',
  },
  transparentBtn: {
    color: '#111111',
    border: '1px solid rgb(222 222 222 / 100%)',
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: 'capitalize',
  }
}));

/**
 *  Page will display the Events information with image in Material Card style UI
 * @param {Component props passed from App.js} props 
 */
export default function EventsToday(props) {
  const classes = useStyles()
  const [eventData] = useState(props.node ? props.node : []);
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h4" component="h1" className={classes.sectionTitle}>
            EVENTS TODAY
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {eventData.length === 0 ? (<div><Typography className={classes.cardSubtitle} >
            There is no event happening right now
          </Typography> </div>) :
            <div>
              {eventData.map(node => (
                <Grid key={node.id} item xs={12}>
                  <Card className={classes.card}>
                    <div className={classes.details}>
                      <CardContent className={classes.content}>
                        <Typography className={classes.details} variant="subtitle1" color="textSecondary">
                          {node.field_agoc_event_category}
                        </Typography>
                        <Typography className={classes.cardSubtitle} component="h5" variant="h5">
                          {node.title}
                        </Typography>
                        <Typography className={classes.sectionbody} variant="body2" color="textSecondary" component="p">
                          {node.field_date_time_description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button href={node.id} style={{ color: "#111111", fontFamily: "Arial", fontSize: 12, fontWeight: "bold" }}>
                          MORE INFO
                        </Button>
                      </CardActions>
                    </div>
                    <CardMedia
                      className={classes.cover}
                      image={node.field_managed_image_url}
                      title=""
                    />
                  </Card>
                </Grid>
              ))}
            </div>
          }
        </Grid> 
      </Grid>
      <Button variant="outlined" href={"https://ago.ca/events/browse"} size="large" className={classes.transparentBtn}>
        FULL CALENDAR
      </Button>
    </div>
  )

}
