import React, { useEffect, useState }  from "react";
import { makeStyles } from "@material-ui/core/styles"; 

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        paddingBottom: 46,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginBottom: 28,
        borderBlockColor: "#707070",

    },
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
        height: 400,
        width: "100%",
    },

    card: {
        marginBottom: "10px",
        width: 270,
        maxWidth: 270,
        minWidth: 270,
        marginRight: 15
    },

    media: {
        height: 270,
    },
    cardContent: {
        paddingLeft: "4%",
        paddingRight: "4%",
        paddingTop: "10px",
        paddingBottom: "0px",
        width: "100%",
        float: "right",
        marginLeft: "0",
        marginRight: "0",
    },
    cardtitle: {
        fontFamily: "CommonGround_Bold",
        fontSize: 17,
        fontWeight: "bold",
        textTransform: 'capitalize',
        // paddingBottom: '5px',
    },
    cardSubTitle: {
        fontFamily: "CommonGround_Normal",
        fontSize: 14,
        fontWeight: "normal",
        // paddingBottom: '5px',
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    },
    transparentBtn: {
        color: '#111111',
        border: '1px solid rgb(222 222 222 / 100%)',
        margin: '8px',
        padding: '8px',
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: "bold",
        textTransform: 'capitalize',
    }

}));
/**
 * Display Sound cloud player
 * @param {*} props 
 * @returns 
 */
export default function AudioGuide(props) {
    const classes = useStyles();   
    const [audioUrl, setAudioUrl]=useState('')
    useEffect(()=>{ 
        console.log('url',props)
        setAudioUrl(props.audio)},[props.audio])
    return (
        <div className={classes.root}>
           <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src={audioUrl}></iframe>         
        </div>
    );
}

/* export  function AudioGuidePicasso(prop) {
    const classes = useStyles();   
    const [audioUrl, setAudioUrl]=useState('')
    useEffect(()=>{ setAudioUrl(url)},[url])
    return (
        <div className={classes.root}>
           <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src={audioUrl}></iframe>
        </div>
    );
} */
