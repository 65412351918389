import { red } from '@material-ui/core/colors';
import { createTheme  } from '@material-ui/core/styles'; 
/* Import custom fonts */
/* import KnockoutHTF28JuniorFeatherwt from './fonts/Knockout-HTF28-JuniorFeatherwt/stylesheet.css';
import KnockoutHTF29JuniorLiteweight from './fonts/Knockout-HTF29-JuniorLiteweight/stylesheet.css';
import NimbusSanBold from  './fonts/NimbusSan-Bold/styles.css';
import NimbusSanReg from  './fonts/NimbusSan-Reg/styles.css'; */
import CommongroundFonts from  './fonts/Commonground/stylesheet.css';

// Custom theme for this app
/**
 * Create a global theme file.Theme file will refer from App.js
 */
const theme = createTheme ({
  typography: {
    fontFamily: 'CommonGround_Normal',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [CommongroundFonts],
      },
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
