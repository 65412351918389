import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box"; 
import Typography from "@material-ui/core/Typography"; 
import Nav from "../components/nav";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Header from "./header";
import "./layout.css";
import { createGlobalStyle } from "styled-components";
import Scroll from './ScrollToTop'
/**
 * Global Style
 */
const GlobalStyle = createGlobalStyle`  
  body {
    
    font-family: 'CommonGround_Normal';
    
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'CommonGround_Bold';
  }
`
const useStyles = makeStyles(theme => ({
  topNav:{
    top:0,
    background: '#e02626',
    boxShadow: 'none'
  },  
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

/**
 *  Main Layout defined Here . Contain Top Navigation bar and Header 
 */
const Layout = ({ history, children }) => {
  const classes = useStyles();
  const theme = useTheme(); 
  return (
    <>
      <GlobalStyle /> 
      <Header siteTitle='AGO' />
      <Nav className={classes.topNav} />
      <div>
      <TabPanel value={0} index={0} dir={theme.direction}>
        <main>{children}</main>
       {history.path!=='/map' && <Scroll showBelow={250} />}
      </TabPanel>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
