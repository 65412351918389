import React from "react";
import { makeStyles } from "@material-ui/core/styles";  
import Typography from "@material-ui/core/Typography"; 
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import usePersistedState from "../usePersistedState"; 
import TimelineExpansionPanels from "./timeline"; 
import French from "./French"; 
import LargePrintText from "./LargePrintText"; 
import AudioGuide from "./AudioGuide"; 
import Feedback from './Feedback'
import { useHistory } from "react-router-dom"; 
const useStyles = makeStyles({
  card: { 
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px"
  },
  media: {
    height: "auto",
    width: "100%",
  },
  sectionTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: '36px',
    fontWeight: "normal", 
    paddingTop: "5px",
    marginRight: "auto",
    maxWidth: 450,
    textAlign: "left",
  }, 
  sectionsubTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: '34px',
    fontWeight: "normal", 
    paddingTop: "5px",
    marginRight: "auto",
    maxWidth: 450,
    textAlign: "left",
  },  
  subTitle: {
    fontFamily:"CommonGround_Normal",
    fontSize: '14px',
    fontWeight: "normal" 
  }, 
  transparentBtn: {
    color: '#111111',
    border: '1px solid rgb(222 222 222 / 100%)',
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: 'capitalize',
  },
  timelineBtn: {
    color: '#111111', 
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12, 
    textTransform: 'capitalize',
    paddingLeft: '0px',
    marginLeft: '0px'
  }
}); 

/**
 * Audience resource page 
 * This page will display Audio guide, Timeline, Videos, Descriptive audio contents
 */
export default function AudienceResources(props) {
  const classes = useStyles();
  let history = useHistory();
  const [homeData, setHomeData] = usePersistedState('homeData', []); 
  const audioGuide = React.useRef(null);
  const timeline = React.useRef(null);
  const video = React.useRef(null);
  const french = React.useRef(null);
  const largePrint = React.useRef(null);
  const feedback = React.useRef(null); 
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  React.useEffect(() => {
    if(props.homeData)
          setHomeData(props.homeData);
  }, [props]); 
  return (
    <div className={classes.root}>
      <Grid style={{ paddingBottom: '72px' }} container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom variant="h4" component="h1" className={classes.sectionTitle}>
        Warhol Audience Resources
    </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography className={classes.subTitle} >
        Audience resources to extend your experience. Please choose:
          </Typography> 
        </Grid> 
        <Grid item xs={12}>
          <Grid container spacing={4}> 
            <Grid item xs={12}>
              <div className={classes.root}>
                <Grid container>
                  <Grid item>
                    <Button variant="outlined" onClick={() => { audioGuide.current.scrollIntoView() }} size="large" className={classes.transparentBtn}>
                      AUDIO GUIDE
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => { timeline.current.scrollIntoView() }}   size="large" className={classes.transparentBtn}>
                      TIMELINE
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => { video.current.scrollIntoView() }} size="large" className={classes.transparentBtn}>
                      VIDEO
                    </Button>
                  </Grid>
                  <Grid style={{ display:'none'}} item>
                    <Button variant="outlined" onClick={() => { largePrint.current.scrollIntoView() }} size="large" className={classes.transparentBtn}>
                      LARGE PRINT
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => {
                      history.push({ pathname: '/descriptive-audio-guide' });
                    }} size="large" className={classes.transparentBtn}>
                      DESCRIPTIVE AUDIO
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button style={{ display:'none'}} variant="outlined" onClick={() => { feedback.current.scrollIntoView() }} size="large" className={classes.transparentBtn}>
                      FEEDBACK
                    </Button>
                  </Grid>
                  <Grid style={{ display:'none'}} item>
                    <Button variant="outlined" onClick={() => { french.current.scrollIntoView() }} size="large" className={classes.transparentBtn}>
                      FRENCH
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid ref={audioGuide} item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                component="h2"
                className={classes.sectionsubTitle}
              >
                AUDIO GUIDE
              </Typography>
            </Grid>
            <Grid item xs={12}>
             <AudioGuide audio={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1289492155%3Fsecret_token%3Ds-9zZDq1ktvNt&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"} />
            </Grid>  
            <Grid style={{ display:'none'}} item xs={12}>
                <LargePrintText node={homeData.Normal}/>
            </Grid>
            <Grid ref={video} item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                component="h1"
                className={classes.sectionsubTitle}
              >
               VIDEO
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/O8HB2cQm_Ag" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid>
            <Grid style={{ display:'none'}} ref={feedback} item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                component="h1"
                className={classes.sectionsubTitle}
              >
               FEEDBACK
              </Typography>
            </Grid>
            <Grid style={{ display:'none'}} item xs={12}>
             <Feedback/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
