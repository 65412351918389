import React from "react";
import { Route } from "react-router-dom";  
import AppBar from "./components/AppBar";
/**
 *  Handling page routing using react-router-dom
 * @param {*} param
 * @returns 
 */
export default function AppliedRoute({ component: C, appProps, homeData, isLoading, ...rest }) {   
  return (
  <Route {...rest} render={props =>
    <>
      <C isLoading={isLoading} homeData={homeData} {...props} {...appProps} />
      <AppBar {...rest} {...appProps} />
    </>} />
  );
}

 ;