import React, { useState, useRef } from "react";
import Layout from "./components/layout"; 
import { useHistory } from "react-router-dom";
import Routes from "./Routes";
import getExhibition from "./util/DataFetcher";
import usePersistedState from "./usePersistedState";
import CircularProgress from "@material-ui/core/CircularProgress";
 
/**
 * This is the main entry page of the App, All other components/page are defined as it's child components
 */
export default function App(props) { 
  const history = useHistory();  
  const firstRefresh = useRef(true);
  const [homeData, setHomeData] = usePersistedState('homeData', []);
  const [isLoading, setIsLoading] = useState(true);
  React.useEffect(() => {  
    if (!homeData || homeData.length==0 || firstRefresh.current) {
      setIsLoading(true);
      /**
       *  This function will Fetch Featured Exhibitions, Exhibitions, Events, Tours, GalleryHours
       *  from ago.ca/jsonapi
       */
      getExhibition.getExhibition({ ...props })
      .then(result => {        
        setHomeData(result);
        firstRefresh.current = false;
        setIsLoading(false);
      }
      )
      .catch(error => {
        setIsLoading(false);
        setHomeData([]);
      }
      );
    }
    else{
      setIsLoading(false);
    }    
  }, [homeData]);
  return (
    <>
       {isLoading && <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "70%",

        }}
      >
        <CircularProgress className='spinner' size={40} style={{ color: "#e02626" }} />
      </div>}
     {!isLoading && <Layout history={history}>  {/* Main Page Layout */}
        <Routes  props={props} appProps={{history, homeData, isLoading, setHomeData}} />
      </Layout>}
    </>
  );
}
