import React from 'react';
import { Route, Switch } from 'react-router-dom'; 
import Home from './components/home';  
/* import NotFound from './components/NotFound'; */
import AppliedRoute from './AppliedRoute'; 
import GalleryMap from "./components/map";
import VisitorInformation from "./components/visitorInformation";
import ExhibitionInfo from "./components/ExhibitionInfo";
import AudienceResources from "./components/AudienceResources";
import PicassoAudienceResources from "./components/PicassoAudienceResources";
import DescriptiveAudioGuide from "./components/DescriptiveAudioGuide";
import ReactGA from 'react-ga';
/**
 * Initialize Google analytics using tracking Id
 */
ReactGA.initialize('UA-3562819-29');

/** 
 * Handle application page routing 
 */
export default function Routes({ props, appProps }) {   
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Switch>
    <AppliedRoute  path='/' exact isLoading={appProps.isLoading} homeData={appProps.homeData} component={Home} appProps={appProps} />  
    <AppliedRoute  path='/map' exact isLoading={appProps.isLoading} homeData={appProps.homeData} component={GalleryMap} appProps={appProps} />  
    <AppliedRoute  path='/exhibition-info' isLoading={appProps.isLoading} exact homeData={appProps.homeData} component={ExhibitionInfo} appProps={appProps} />  
    <AppliedRoute  path='/info' exact isLoading={appProps.isLoading} homeData={appProps.homeData} component={VisitorInformation} appProps={appProps} />  
    <AppliedRoute  path='/andy-warhol' isLoading={appProps.isLoading} exact homeData={appProps.homeData} component={AudienceResources} appProps={appProps} />  
    <AppliedRoute  path='/picasso' isLoading={appProps.isLoading} exact homeData={appProps.homeData} component={PicassoAudienceResources} appProps={appProps} />  
    <AppliedRoute  path='/descriptive-audio-guide' isLoading={appProps.isLoading} homeData={appProps.homeData} exact component={DescriptiveAudioGuide} appProps={appProps} />  
    {/* <Route component={NotFound} /> */}
    </Switch>
  );
}
