import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";  
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
import LevelOne from "../images/AGO_maps_level-1.png";
import LevelTwo from "../images/AGO_maps_level-2.png";
import LevelFour from "../images/AGO_maps_level-4.png";
import LevelFive from "../images/AGO_maps_level-5.png";
import LevelConcourse from "../images/AGO_maps_lower-level-concourse.png"; 
import MapLegend from "../images/map-legend_-grey.png";
import Fab from "@material-ui/core/Fab"; 
import RoomIcon from "@material-ui/icons/Room";
import Control from "react-leaflet-control";
import { Map, ImageOverlay, ZoomControl } from "react-leaflet";
import L from "leaflet";

function TabPanel(props) {
  const { children, value, index, ...other } = props; 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box style={{ paddingTop: 71 }}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  image: {
    maxWidth: 350,
    userDrag: "none"
  },
  root1: {
    display: "inline-block",
    position: "relative"
  },
  fab: {
    boxShadow:"none",
    color: "#111111",
    backgroundColor: "#F0F0F0",
    border:"2px solid rgba(0,0,0,0.2)",
    backgroundClip:"padding-box",
    borderTopRightRadius:"0px",
    borderTopLeftRadius:"0px",
    position: "absolute",
    top: "-12px",
    left: "-34px",
    zIndex: 10000,
    width: "34px",
    height: "30px",
    lineHeight:"30px",
    textAlign: "center",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTop: "1px solid #ccc"   
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    marginRight:"0px"
  },
  list: {
    width: 250
  },
  fullList: {
    fontFamily: "CommonGround_Normal",
    width: "auto"
  },
  listItemText:{
    fontFamily: "CommonGround_Normal"
  },
  headderAppBar: {
    background: "#e02626",
    fontFamily: "Oswald",
  },
  tabs: {
    background: "#111111",
    fontFamily:'CommonGround_Normal'
  },
  tab: {
    fontFamily: "CommonGround_Bold",
    fontSize: 13,
    fontWeight: "bold",
    color: "White"
  }
}));
/**
 *  Bottom button "MAP" will navigate to this page
 *  Page is divided into six tabs and showing different map from Ago gallery building map
 */
export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0); 
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const colors = [
    "#E49A50",
    "#8AD8FA",
    "#9F0026",
    "#8F5A8B",
    "#CBD354",
    "#D5CFB4",
    "#7998CD"
  ];

  const Circle = props => {
    const circleStyle = {
      padding: 5,
      margin: 10,
      display: "inline-block",
      backgroundColor: props.bgColor,
      borderRadius: "50%",
      width: 20,
      height: 20
    };
    return <div style={circleStyle}></div>;
  };

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  /**
   * Drawer will show the explanation to each colors in the Map
   * @param { Side from which the drawer will appear. Possible values are 'bottom' | 'left' | 'right' | 'top side 
   */
  const fullList = side => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
    {/*   <List>
        {[
          "AFRICAN ART",
          "CONTEMPORARY ART",
          "EUROPEAN ART",
          "MODERN ART",
          "WESTON FAMILY CENTRE",
          "ZACKS SPECIAL EXHIBITION",
          "PHOTOGRAPHY"
        ].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {<Circle key={index + colors[index]} bgColor={colors[index]} />}
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={text} />
          </ListItem>
        ))}
      </List> */} 
      <img  src={MapLegend} width='100%' />
    </div>
  );
  const style = {
    width: "100%",
    height: "auto",
    boxShadow: "none",
    textAlign: "center",
    display: "inline-block"
  };

  return (
    <div className={classes.root}>
      <style>
        {
          "\
          .leaflet-pane {\
            z-index: 400;\
            transform: translate3d(8px, 256px, 0px);\
        }\
        .App {\
          font-family: sans-serif;\
          text-align: center;\
        }\
        .leaflet-div-icon {\
          background: transparent;\
          border: none;\
        }\
        .button {\
          position: absolute;\
          top: 100px;\
          left: 100px;\
          z-index: 10000;\
        }\
        .leaflet-container {\
          height:500px; \
          background: #F0F0F0 \
          width: 100%;\
        }\
        .leaflet-bar a:last-child{\
          border-bottom-left-radius: 0px;\
          border-bottom-right-radius: 0px;\
          border-bottom: none;\
        }\
        #map {width: 100%; height:100%; position: absolute; }\
        }\
      "
        }
      </style>
        {/*  Here 6 Tabs are there, all tabs contain Maps of Ago Gallery and Used React Leaflet tool to showing the Maps */}
        {/* React Leaflet tool can used to drag map image to any direction and zoom in/out features are also there*/}
         <AppBar /* className={classes.headderAppBar} */ style={{ top: "56px", boxShadow:"none" }}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        > 
         <Tab
            className={classes.tab}
            label="Lower Level (Concourse)"
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label="Level 1"
            {...a11yProps(1)}
          />
          <Tab className={classes.tab} label="Level 2" {...a11yProps(2)} />
          {/* <Tab className={classes.tab} label="Level 3" {...a11yProps(3)} /> */}
          
          <Tab className={classes.tab} label="Level 4" {...a11yProps(3)} />
          <Tab className={classes.tab} label="Level 5" {...a11yProps(4)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Paper style={style} >
          <Map
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelConcourse}
              bounds={[
                [301, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>     
        </Paper>        
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper  style={style}> 
          <Map
            center={[0, 0]}
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelOne}
              bounds={[
                [304, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>       
        </Paper>        
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Paper style={style} >
          <Map
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelTwo}
              bounds={[
                [308, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>         
        </Paper>       
      </TabPanel> 
    {/*   <TabPanel value={value} index={3}>
        <Paper style={style} >
          <Map
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelThree}
              bounds={[
                [313.05, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>
 
        </Paper>       
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <Paper style={style} >
          <Map
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelFour}
              bounds={[
                [564, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>      
        </Paper>        
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Paper style={style} >
          <Map
            attributionControl={false}
            touchZoom={true}
            zoomControl={false}
            doubleClickZoom={false}
            crs={L.CRS.Simple}
            bounds={[
              [-250, -50],
              [500, 250]
            ]}
          >
            <ImageOverlay
              url={LevelFive}
              bounds={[
                [576, 0],
                [0, 500]
              ]}
            />
            <ZoomControl position="topright" />
            <Control position="topright">            
              <Fab
            onClick={toggleDrawer("bottom", true)}           
            aria-label="legend"
            className={classes.fab}
          >
            <RoomIcon className={classes.extendedIcon} />           
          </Fab>
            </Control>
          </Map>  
        </Paper>
      </TabPanel>
      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer("bottom", false)}
      >
        {fullList("bottom")}
      </Drawer>
    </div>
  );
}
