import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  headderAppBar:{
    background: '#111111',
    fontFamily: 'CommonGround_Normal',
    boxShadow:'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    flexGrow: 1,
    margin: '0 auto',
    width: 50,
    height: 38,
  
  },
 
})); 
/**
 * Top Navigation bar. Ago log will place here
 */
export default function Nav() {
const classes = useStyles(); 
return (
    <AppBar position="sticky" className={classes.headderAppBar}>
      <Toolbar className={classes.logo}>           
          <img src='./logo.svg' className={classes.logo}/>
      </Toolbar>
    </AppBar>
);
}
