import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList"; 
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia"; 
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography"; 
import ButtonBase from '@material-ui/core/ButtonBase';
 
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingBottom: 46,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    marginBottom: 28,
    borderBlockColor:"#707070", 
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    height: 400,
    width: "100%",
  },

  card: {
    marginBottom: "10px",
    width: 270,
    maxWidth: 270,
    minWidth: 270,
    marginRight: 15
  },
 
  media: {
    height: 270,
  },
  cardContent:{
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "10px",
    paddingBottom: "0px",
    width: "100%",
    float: "right",
    marginLeft: "0",
    marginRight: "0",    
  },
  cardtitle: {
    fontFamily:"CommonGround_Bold",
    fontSize:17,
    fontWeight:"bold",
    textTransform: 'capitalize',
    // paddingBottom: '5px',
  },
  cardSubTitle: {
    fontFamily:"CommonGround_Normal",
    fontSize:14,
    fontWeight:"normal",
    // paddingBottom: '5px',
  }, 
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  transparentBtn:{
    color: '#111111', 
    border: '1px solid rgb(222 222 222 / 100%)',
    margin:'8px',
    padding:'8px',
    fontFamily:"Arial",
    fontSize:12,
    fontWeight:"bold",    
    textTransform: 'capitalize',
  }

})); 

/**
 *  Exhibition other than 'Featured' will list here
 */
export default function FeatureSlide(props) {
  const classes = useStyles(); 
  const [exbData] = useState(props.node?props.node:[]);
    function clickOverview(dataNode){
     props.onChildClick(dataNode);   
  }
  return (
    <div className={classes.root}>              
      <ImageList  className={classes.gridList} cols={1.2}>
        {exbData.map((node,indx)  => (
           <div key={node.id} >      
           <Card className={classes.card}>
           <ButtonBase  className={classes.cardAction} onClick={event => {clickOverview({...node})}}>
             <CardMedia
               component="img"               
               className={classes.media}
               image={node.field_managed_image_url}
               alt={ node.field_managed_image_alt? node.field_managed_image_alt:""}
               title={node.field_managed_image_title?node.field_managed_image_title:""}
             />
             <CardContent>
             <Typography
                  className={classes.cardtitle}
                  gutterBottom
                  variant="h5"
                  component="h3"
                  color="textPrimary"
                >
                  {node.title}
                </Typography>
                <Typography 
                  className={classes.cardSubTitle}
            gutterBottom
            variant="h5"
            component="h4"
            color="textSecondary"
          >
            {node.field_date_time_description}
          </Typography>
             </CardContent>
             </ButtonBase>
           </Card>  
           </div> 
        ))}
      </ImageList>
      <Button variant="outlined"  href={"https://ago.ca/exhibitions"}  size="large"  className={classes.transparentBtn}>
         ALSO ON VIEW
        </Button>
      
    </div>
  );
}
