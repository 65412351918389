import React from "react";
import { makeStyles } from "@material-ui/core/styles";  
import Typography from "@material-ui/core/Typography"; 
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import usePersistedState from "../usePersistedState"; 
import TimelineExpansionPanels from "./timeline"; 
import French from "./French"; 
import LargePrintText from "./LargePrintText"; 
import AudioGuide from "./AudioGuide"; 
import Feedback from './Feedback'
import { useHistory } from "react-router-dom"; 
const useStyles = makeStyles({
  card: { 
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "10px"
  },
  media: {
    height: "auto",
    width: "100%",
  },
  sectionTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: '36px',
    fontWeight: "normal", 
    paddingTop: "5px",
    marginRight: "auto",
    maxWidth: 450,
    textAlign: "left",
  }, 
  sectionsubTitle: {
    fontFamily: "CommonGround_Bold",
    fontSize: '34px',
    fontWeight: "normal", 
    paddingTop: "5px",
    marginRight: "auto",
    maxWidth: 450,
    textAlign: "left",
  },  
  subTitle: {
    fontFamily:"CommonGround_Normal",
    fontSize: '14px',
    fontWeight: "normal" 
  }, 
  transparentBtn: {
    color: '#111111',
    border: '1px solid rgb(222 222 222 / 100%)',
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12,
    fontWeight: "bold",
    textTransform: 'capitalize',
  },
  timelineBtn: {
    color: '#111111', 
    margin: '8px',
    padding: '8px',
    fontFamily: "Arial",
    fontSize: 12, 
    textTransform: 'capitalize',
    paddingLeft: '0px',
    marginLeft: '0px'
  }
}); 

/**
 * Audience resource page 
 * This page will display Audio guide, Timeline, Videos, Descriptive audio contents
 */
export default function PicassoAudienceResources(props) {
  const classes = useStyles();
  let history = useHistory();
  const [homeData, setHomeData] = usePersistedState('homeData', []); 
  const audioGuide = React.useRef(null);
  const timeline = React.useRef(null);
  const video = React.useRef(null);
  const french = React.useRef(null);
  const largePrint = React.useRef(null);
  const feedback = React.useRef(null); 
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  React.useEffect(() => {
    if(props.homeData)
          setHomeData(props.homeData);
  }, [props]); 
  return (
    <div className={classes.root}>
      <Grid style={{ paddingBottom: '72px' }} container spacing={2}>
        <Grid item xs={12}>
        <Typography gutterBottom variant="h4" component="h1" className={classes.sectionTitle}>
        Picasso Audience Resources
    </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography className={classes.subTitle} >
        Audience resources to extend your experience. Please choose:
          </Typography> 
        </Grid> 
        <Grid item xs={12}>
          <Grid container spacing={4}>  
            <Grid ref={audioGuide} item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                component="h2"
                className={classes.sectionsubTitle}
              >
                AUDIO GUIDE
              </Typography>
            </Grid>
            <Grid item xs={12}>
             <AudioGuide audio={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1328240104&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"} />
         
            </Grid>  
            <Grid style={{ display:'none'}} item xs={12}>
                <LargePrintText node={homeData.Normal}/>
            </Grid>
            <Grid ref={video} item xs={12}>
              <Typography
                gutterBottom
                variant="h4"
                component="h1"
                className={classes.sectionsubTitle}
              >
               RESOURCES
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/PAfgygbkyrE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="415" src="https://www.youtube.com/embed/EXqge0uNO_w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="415" src="https://www.youtube.com/embed/z14D8PMHpTw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="415" src="https://www.youtube.com/embed/aPiDoDQyFLQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="415" src="https://www.youtube.com/embed/UC2g3zlQA7Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
            <Grid item xs={12}>
            <div className={classes.root}>
            <iframe width="100%" height="415" src="https://www.youtube.com/embed/lKIJXVB1tvY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            </Grid> 
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
