import axios from "axios";
var apiUrl = "https://phv6eg380l.execute-api.us-east-1.amazonaws.com/api/fetch";  /* We had faced some CORS issue while requesting data from Drupal API directly. S
So we create a proxy API that will do the requesting to Drupal and return the result  */
var getExibData = {
  /**
   * Function will loop the array to get the id of each elements. Using this id get the detailed information from Ago API return it
   * @param { Parameter will contain an array of Ago api data   } res 
   * @param {Type will be Tours,GalleryHours,Events etc } type 
   */
  getBulkExbDetails: async (res, type) => {
    var allData = [];
    return new Promise(async (resolve, reject) => {
      try {
        const promises = await res.map(async (val, ind) => {
          var obj = {};
          if (!val && val == undefined) {
            return {};
          }
          if (type === "Tours") {
            var exResp = await getExibData.getTourDetails(val.id);
            var obj = {};
            obj.id = val.id;
            obj.title = val.attributes.title;
            obj.id = "https://ago.ca" + val.attributes.path.alias;
            obj.field_managed_image_alt =
              exResp.data.included[0].relationships.field_managed_image.alt;
            obj.field_managed_image_title =
              exResp.data.included[0].relationships.field_managed_image.title;
            obj.field_managed_image_url =
              "https://ago.ca" + exResp.data.included[1].attributes.uri.url;
            return obj;
          } else if (type === "GalleryHours") {
            if (val.attributes.title === "Gallery Hours") { 
              var exResp = await getExibData.getGalleryHours(val.id); 
              const promises1 = await exResp.data.included.map(
                async (hval, hind) => {
                  var obj = {};
                  obj.field_agoc_day_of_the_week_text =
                    hval.attributes.field_agoc_day_of_the_week_text;
                  obj.field_agoc_start_time = getExibData.tConv24(
                    hval.attributes.field_agoc_start_time
                  );
                  obj.field_agoc_end_time = getExibData.tConv24(
                    hval.attributes.field_agoc_end_time
                  );
                  return obj;
                }
              );
              const hourData = await Promise.all(promises1); 
              return hourData;
            } else {
              return false;
              //do nothing
            }
          } else if (type === "Events") {
            var exResp = await getExibData.getEventDetails(val.id);
            var obj = {};
            obj.id = val.id;
            obj.title = val.attributes.title;
            obj.id = "https://ago.ca" + val.attributes.path.alias;
            obj.field_managed_image_alt =
              exResp.data.included[0].relationships.field_managed_image.alt;
            obj.field_managed_image_title =
              exResp.data.included[0].relationships.field_managed_image.title;
            obj.field_managed_image_url =
              "https://ago.ca" + exResp.data.included[1].attributes.uri.url; 
            obj.field_agoc_event_category =exResp.data.included.length>=3? exResp.data.included[2].attributes.name:""; 
            obj.field_date_time_description =
              val.attributes.field_date_time_description; 
            return obj;
          } else {
            var exResp = await getExibData.getExbDetails(val.id);
            obj.id = val.id;
            obj.title = val.attributes.title;
            obj.id = "https://ago.ca" + val.attributes.path.alias;
            obj.field_managed_image_alt =
              exResp.data.included[0].relationships.field_managed_image.alt;
            obj.field_managed_image_title =
              exResp.data.included[0].relationships.field_managed_image.title;
            obj.field_managed_image_url =
              "https://ago.ca" + exResp.data.included[1].attributes.uri.url;  
             console.log('title',  val.attributes.title)
              exResp.data.included.map(function(included,idx) { 
                if (included.type === 'node--ago_locations') { 
                  console.log('included.type',included.attributes.title);
                  obj.field_agoc_multi_locations =included.attributes.title;  
                }
                if (included.type === 'node--ago_free_form' && included.attributes.title.includes('Exhibition Overview')) { 
                  var content =included.attributes.body.processed;
                  var result = content.replace(
                    /\/sites\/default/g,
                    "https://ago.ca/sites/default/"
                  );
                  obj.field_referenced_content = result;
                  obj.field_referenced_title =included.attributes.title;
                }
            })  
            /* if (
              exResp.data.included.length > 3 &&
              exResp.data.included[4].type == "node--ago_free_form" &&
              exResp.data.included[4].attributes.title.includes('Exhibition Overview')
            ) {
              console.log('content',content);
              var content = exResp.data.included[4].attributes.body.processed;
              var result = content.replace(
                /\/sites\/default/g,
                "https://ago.ca/sites/default/"
              );
              obj.field_referenced_content = result; //exResp.data.included[2].attributes.body.processed;
              obj.field_referenced_title =
                exResp.data.included[4].attributes.title;
              obj.field_agoc_multi_locations = "";
            } else if (
              exResp.data.included.length > 2 &&
              exResp.data.included[2].type == "node--ago_free_form"
            ) {
              var content = exResp.data.included[2].attributes.body.processed;
              var result = content.replace(
                /\/sites\/default/g,
                "https://ago.ca/sites/default/"
              );
              obj.field_referenced_content = result; //exResp.data.included[2].attributes.body.processed;
              obj.field_referenced_title =
                exResp.data.included[2].attributes.title;
              obj.field_agoc_multi_locations = "";
            } else {
              obj.field_agoc_multi_locations = exResp.data.included.length>2? exResp.data.included[2].attributes.title:'';
              var content = exResp.data.included.length>3? exResp.data.included[3].attributes.body.processed:'';
              var result = content.replace(
                /\/sites\/default/g,
                "https://ago.ca/sites/default/"
              );
              obj.field_referenced_content = result; //exResp.data.included[2].attributes.body.processed;
              obj.field_referenced_title = exResp.data.included.length>3? exResp.data.included[3].attributes.title:'';
            } */
            obj.field_date_time_description =
              val.attributes.field_date_time_description;

            return obj;
          }
        });
        allData = await Promise.all(promises);
        resolve(allData);
      } catch (error) {
        console.log("An error occurred.");
        reject(error);
      }
    });
  },
    /**
   * Function will loop the Ago Gallery Hours array and get the id of each elements. Using this id get the detailed information from Ago API return it
   * @param { Parameter will contain an array of Ago Gallery Hours data   } res  
   */
  getGalleryHoursData:async (res) =>{
    var allData = [];
    return new Promise(async (resolve, reject) => {
      try {
        let promises=await  res.map(async (val, ind) => {                 
          if (val.attributes.title === "Gallery Hours") { 
            var exResp = await getExibData.getGalleryHours(val.id);
            const promises1 = await exResp.data.included.map(
              async (hval, hind) => {
                var obj = {};
                obj.field_agoc_day_of_the_week_text =
                  hval.attributes.field_agoc_day_of_the_week_text; 
                  if( hval.attributes.field_agoc_end_time===null)
                  {                   
                    obj.field_agoc_start_time='CLOSED';
                  }
                  else
                  {
                    obj.field_agoc_start_time = getExibData.tConv24(
                      hval.attributes.field_agoc_start_time
                    );
                    obj.field_agoc_end_time = getExibData.tConv24(
                      hval.attributes.field_agoc_end_time
                    );                    
                  }               
                return obj;
              }
            );
            const hourData = await Promise.all(promises1); 
            return hourData;
          }
          else
          {
            return false;
          } 
        }); 
        allData=await Promise.all(promises)     
        let newData= allData.filter((gData, index) => {              
          return gData !=false
        })  
        resolve(newData[0]);
      } catch (error) {
        console.log("An error occurred.");
        reject(error);
      }
    });
  },
  /**
   * Convert the clock time get from Drupal to 24 Hour time format
   * @param {Drupal Time as integer} time24 
   */
  tConv24: function(time24) {
    var ts = (time24 / 2).toString();
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    var minu = ts.substr(2, 3); 
    ts = h + ":" + (minu ? parseFloat(minu) * 60 : "00") + ampm;
    return ts;
  },
  /**
   * Function will get Ago Exhibition data
   * 1. API Post 1 -  Get Upcoming 'Featured' Ago Exhibition data , 
   * filter condition is Ago exhibition start before one month from today 
   * and end date after one month from today and Exhibition type equal to 'Special/Featured'
   * 2. API Post 2 -  Get Ago Exhibition data , filter condition is Ago exhibition start date
   *  within One month and Exhibition type equal to 'Ongoing/Gallery' OR 'Standard'
   * 3. API Post 3 -  Get Tour details
   * 4. API Post 4 - Get Upcoming events within a month 
   * 3. API Post 5 - Get the Ago hours / Visitor Information
   * @param {event props} props 
   */
  getExhibition: async props => {
    var allData = { Fetured: [], Normal: [], Events: [], Tours: [] };
    var d = new Date();
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return new Promise(async (resolve, reject) => {
      await axios
        .all([
          axios.post(apiUrl, {
            url:"http://ago.ca/jsonapi/node/agoc_exhibition? include=field_agoc_exhibition_type&filter[date2][condition][path]=field_agoc_exhibition_type.name&filter[date2][condition][operator]==&filter[date2][condition][value]=Special/Featured&filter[date1][condition][path]=field_agoc_start_date&filter[date1][condition][operator]=<=&filter[date1][condition][value]="+datestring+"&filter[date3][condition][path]=field_agoc_end_date&filter[date3][condition][operator]=>=&filter[date3][condition][value]="+datestring+"&sort[sort-field_agoc_start_date][path]=field_agoc_start_date&sort[sort-field_agoc_start_date][direction]=ASC",
            method: "GET",
            data: {}
          }),
          axios.post(apiUrl, {
            url:
              "http://ago.ca/jsonapi/node/agoc_exhibition? include=field_agoc_exhibition_type&filter[root1][group][conjunction]=AND& filter[field_agoc_exhibition_type][group][conjunction]=OR&filter[field_agoc_exhibition_type][group][memberOf]=root1&filter[title1][condition][path]=field_agoc_exhibition_type.name&filter[title1][condition][value]=Ongoing/Gallery in Focus&filter[title1][condition][memberOf]=field_agoc_exhibition_type&filter[title2][condition][path]=field_agoc_exhibition_type.name&filter[title2][condition][value]=Standard&filter[title2][condition][memberOf]=field_agoc_exhibition_type&filter[dateg][group][conjunction]=OR&filter[dateg][group][memberOf]=root1&filter[title3][condition][path]=field_agoc_start_date&filter[title3][condition][value]=" +
              datestring +
              "&filter[title3][condition][operator]=<&sort[sort-start_date][path]=field_agoc_start_date&sort[sort-start_date][direction]=DESC&page[limit]=5&page[skip]=1&filter[title3][condition][memberOf]=dateg",
            method: "GET",
            data: {}
          }),
          axios.post(apiUrl, {
            url: "http://ago.ca/jsonapi/node/agoc_tours",
            method: "GET",
            data: {}
          }),
          axios.post(apiUrl, {
            url:
              "http://ago.ca/jsonapi/node/agoc_event?filter[date][condition][path]=field_agoc_event_session.field_agoc_start_date&filter[date][condition][operator]==&filter[date][condition][value]=" +
              datestring,
            method: "GET",
            data: {}
          }),
          axios.post(apiUrl, {
            url: "https://ago.ca/jsonapi/node/ago_hours",
            method: "GET",
            data: {}
          })
        ]) 
        .then(async response => {  
          allData.Fetured = await getExibData.getBulkExbDetails(
            response.length > 0 ? response[0].data.data : [],
            "Exhibition"
          );
          allData.Normal = await getExibData.getBulkExbDetails(
            response.length > 0 ? response[1].data.data : [],
            "Exhibition"
          );
          allData.Tours = await getExibData.getBulkExbDetails(
            response.length > 0 ? response[2].data.data : [],
            "Tours"
          );
          allData.Events = await getExibData.getBulkExbDetails(
            response.length > 0 ? response[3].data.data : [],
            "Events"
          );
          allData.GalleryHours = await getExibData.getGalleryHoursData(
            response.length > 0 ? response[4].data.data : [],
            "GalleryHours"
          ); 
          resolve(allData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }, 
  getAgoHours: async props => {
    var allData = []
    var d = new Date();
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return new Promise(async (resolve, reject) => {
      await axios
        .all([         
          axios.post(apiUrl, {
            url: "https://ago.ca/jsonapi/node/ago_hours",
            method: "GET",
            data: {}
          })
        ]) 
        .then(async response => {          
          allData = await getExibData.getGalleryHoursData(
            response.length > 0 ? response[4].data.data : [],
            "GalleryHours"
          ); 
          resolve(allData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   *  Using Ago Tour Id API will get the Image URL, Image Title and Image Alt from Drupal
   * @param {Id of Ago Tour for uniquely identify the Tour data entry in Drupal } id 
   */
  getTourDetails: async id => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(apiUrl, {
          url: `http://ago.ca/jsonapi/node/agoc_tours/${id}?include=field_agoc_image,field_agoc_image.field_managed_image&fields[field_agoc_image]=field_managed_image`,
          method: "GET",
          data: {}
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }, 

    /**
   *  Using Ago Gallery Hours Id, API will get the details of the gallery working days in a week from Drupal
   * @param {Id of Gallery Hours entry in Drupal } id 
   */
  getGalleryHours: async id => {
    return new Promise(async (resolve, reject) => { 
      await axios
        .post(apiUrl, {
          url: `https://ago.ca/jsonapi/node/ago_hours/${id}?include=field_hours`,
          method: "GET",
          data: {}
        })
        .then(response => { 
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  /**
   *  Using Ago Event Id, API will get the Image URL, Image Title and Image Alt from Drupal
   * @param {Id of Ago Event for uniquely identify the Event data entry in Drupal } id 
   */
  getEventDetails: async id => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(apiUrl, {
          url: `http://ago.ca/jsonapi/node/agoc_event/${id}?include=field_agoc_image,field_agoc_image.field_managed_image,field_agoc_event_category&fields[field_agoc_image]=field_managed_image`,
          method: "GET",
          data: {}
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
    /**
   *  Using Ago Exhibition Id, API will get the Image URL, Image Title and Image Alt from Drupal
   * @param {Id of Ago Exhibition for uniquely identify the Exhibition data entry in Drupal } id 
   */
  getExbDetails: async id => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(apiUrl, {
          url: `http://ago.ca/jsonapi/node/agoc_exhibition/${id}?include=field_agoc_image,field_agoc_image.field_managed_image,field_agoc_multi_locations,field_referenced_content&fields[field_agoc_image]=field_managed_image`,
          method: "GET",
          data: {}
        })
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

export default getExibData; 