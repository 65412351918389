import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
 
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingBottom: 46, 
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    height: 400,
    width: "100%",
  },

  card: {
    marginBottom: "10px",
    width: 270,
    maxWidth: 270,
    minWidth: 270,
    marginRight: 15
  },
 
  media: {
    height: 270,
  },
  cardContent:{
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "10px",
    paddingBottom: "0px",
    width: "100%",
    float: "right",
    marginLeft: "0",
    marginRight: "0",    
  },
  cardtitle: {
    fontFamily:"CommonGround_Bold",
    fontSize:17,
    fontWeight:"bold",
    textTransform: 'capitalize',
    // paddingBottom: '5px',
  },
  cardSubTitle: {
    fontFamily:"CommonGround_Bold",
    fontSize:20,
    fontWeight:"bold",
    textTransform: 'uppercase',
    // paddingBottom: '5px',
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial'
  },
  transparentBtn:{
    color: '#111111',  
    margin:'8px',
    padding:'8px',
    fontFamily:"Arial",
    fontSize:12,
    fontWeight:"bold",    
    textTransform: 'capitalize',
  },
  title: {
    fontSize: 14,
  },

})); 

/**
 * Link to Feedback page and view feedback page
 * @param {*} props 
 * @returns 
 */
export default function Feedback(props) {
  const classes = useStyles(); 
  return (
      <div className={classes.root}>
          <Grid container spacing={2}>       
        <Grid item xs={12}>
        <Card >
              <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    QUESTION 1
                  </Typography>
                  <Typography className={classes.cardSubTitle}  component="h5" variant="h5">
                    Do you think Warhol is expressing empathy in these portraits of New York’s trans community, or are these works exploitative?
                  </Typography>                  
              </CardContent>
              <CardActions>
                  <Button href='https://www.warhol.ago.ca/1' className={classes.transparentBtn} size="small">LEAVE A RESPONSE</Button>
                  <Button href='https://www.warhol.ago.ca/1/reports'  className={classes.transparentBtn} size="small">VIEW RESPONSES</Button>
              </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
             
          <Card >
              <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    QUESTION 2
                  </Typography>
                  <Typography className={classes.cardSubTitle}  component="h5" variant="h5">
                  When you think of America and the Statue of Liberty, do military camouflage and brand names come to mind, or do you think of something else?
                  </Typography>                  
              </CardContent>
              <CardActions>
                  <Button href='https://www.warhol.ago.ca/2' className={classes.transparentBtn} size="small">LEAVE A RESPONSE</Button>
                  <Button href='https://www.warhol.ago.ca/2/reports'  className={classes.transparentBtn} size="small">VIEW RESPONSES</Button>
              </CardActions>
          </Card>
            </Grid>
        </Grid>
      
      </div>
  );
}
