import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button"; 
import CardContent from "@material-ui/core/CardContent"; 
import Typography from "@material-ui/core/Typography"; 
import Grid from "@material-ui/core/Grid";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';  

const useStyles = makeStyles({
    card: {
        // maxWidth: 450,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "10px"
    },
    media: {
        height: "auto",
        width: "100%",
    },
    sectionTitle: {
        fontFamily: "CommonGround_Bold",
        fontSize: '34px',
        fontWeight: "noramal",
        textTransform: 'uppercase',
        paddingTop: "5px",
        marginRight: "auto",
        maxWidth: 450,
        textAlign: "left",
    },
    sectionTitle2: {
        fontFamily: "CommonGround_Bold",
        fontSize: '34px',
        fontWeight: "noramal",
        paddingTop: "5px",
        marginRight: "auto",
        maxWidth: 450,
        textAlign: "left",
    },
    cardtitle: {
        fontFamily: "CommonGround_Bold",
        fontSize: '34px',
        fontWeight: "bold",
        textTransform: 'uppercase',
        color: '#F0F0F0'
        // paddingBottom: '5px',
    },
    cardSubTitle: {
        fontFamily: "CommonGround_Normal",
        fontSize: '14px',
        fontWeight: "normal",
        color: '#F0F0F0'
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial',
        width: "100%",
    },
    transparentBtn: {
        width:'100%',
        color: '#111111',
        border: '1px solid rgb(222 222 222 / 100%)',
        margin: '8px',
        padding: '8px',
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: "bold",
        textTransform: 'capitalize',
    },
    sectionbody: {
        fontFamily: "CommonGround_Normal",
        color: '#707070'
    },
    subTitle: {
        fontFamily: "CommonGround_Normal",
        fontSize: '14px',
        fontWeight: "normal"
    },
    timelineBtn: {
        color: '#111111',
        margin: '8px',
        padding: '8px',
        fontFamily: "Arial",
        fontSize: 12,
        textTransform: 'capitalize',
        paddingLeft: '0px',
        marginLeft: '0px'
    }
});

export const FiCardContent = withStyles({
    root: {
        position: "absolute",
        bottom: 55,
        left: 20.
    }
})(CardContent);

/**
 *  Descriptive audio inside expansion panel
 *  Page Descriptive audio guide
 */
export default function DescriptiveAudioGuide(props) {
    const classes = useStyles(); 
    const [expanded, setExpanded] = React.useState(false);     
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };  
    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div className={classes.root}>
            <Grid style={{ paddingBottom: '72px' }} container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography
                                gutterBottom
                                variant="h4"
                                component="h1"
                                className={classes.sectionTitle2}
                            >
                                DESCRIPTIVE AUDIO GUIDE
                            </Typography>
                            <Button target="_blank"  href='https://soundcloud.com/agotoronto/sets/andy-warhol-described-audio' className={classes.timelineBtn} size="small">
                                <Typography className={classes.subTitle} >
                                    Source: Courtesy of Tate Modern
                                </Typography>
                            </Button>
                        </Grid>                       
                        <Grid item xs={12}>
                            <ExpansionPanel
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className={classes.heading}>
                                        Stop 1: I Like Dance, 1947
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1092146212&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={classes.heading}>Stop 2: Elvis I and II, 1963–1964</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1092146233&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography className={classes.heading}>
                                    Stop 3: Flowers, 1964
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1092146218&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expanded === "panel5"}
                                onChange={handleChange("panel5")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel5bh-header"
                                >
                                    <Typography className={classes.heading}>Stop 4: Dolly, 1985</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1092146251&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expanded === "panel6"}
                                onChange={handleChange("panel6")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                >
                                    <Typography className={classes.heading}>
                                    Stop 5: Camouflage, 1986
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1092146269&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={expanded === "panel7"}
                                onChange={handleChange("panel7")}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                >
                                    <Typography className={classes.heading}>
                                        Stop 6: Track Title
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/343977175&color=ff3d00"></iframe>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                </Grid>               
                <Grid style={{display:'none'}} item xs={12}  sm={12}>
                    <Grid container direction="row" justifyContent="center"  alignItems="center">
                        <Grid item xs={12}>
                            <Button variant="outlined" href={"https://www.warhol.ago.ca/1"} size="large" className={classes.transparentBtn}>
                                LARGE FORMAT DESCRIPTIVE
                            </Button>
                        </Grid>
                        <Grid item  xs={12}  sm={12}>
                            <Button variant="outlined" href={"https://ago.ca/exhibitions"} size="large" className={classes.transparentBtn}>
                                TRANSCRIPT
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    );
}
